<template>
  <div>
    <strong>
      {{ $t("role.orders.rounds.ddmp.detail.tabs.orders.table.orders") }} #{{
        getOrderId()
      }}</strong
    >
    <br />
    <span>{{ getFamilyName() }}</span>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrdersTableName",

  data() {
    return {
      data: {},
    };
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    getOrderId() {
      return get(this.data, `order.order.id`);
    },

    getFamilyName() {
      return `F${get(this.data, "order.order.family.id", "")} ${get(
        this.data,
        "order.order.family.name",
        ""
      )}`;
    },
  },
};
</script>
